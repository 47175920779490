<template>
  <div class="plan-num">
    <template v-if="planMemoScene == 1">
      <div class="plan-num-tab">
        <div class="plan-tab-btn" v-for="(item, index) in tabList" :key="index"
          :class="currentIndex === index ? 'active' : ''" @click="tabClick(item, index)">
          {{ item.name }}
        </div>
      </div>
      <div class="studyTime" v-show="currentIndex === 0">
        <div class="text">每日学时 (分)</div>
        <el-select v-model="planMemoTime" filterable allow-create placeholder="选择学习时长" @change="timeChange">
          <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="studyTime" v-show="currentIndex === 1">
        <div class="text">每日学习新卡数 (张)</div>
        <el-select v-model="planNewCardNum" filterable allow-create placeholder="选择学习卡数" @change="numChange">
          <el-option v-for="item in cardList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="plan-tips">
        Tips：{{ currentIndex === 0 ? '按每天投入的时间，自动安排每天学习卡片数量' : '按每天学习新卡数，自主安排每天学习卡片数量' }}
      </div>
    </template>
    <template v-else>
      <div class="plan-deadline">
        <div class="text">距离考试日期</div>
        <div class="time">{{surplusDay}}</div>
      </div>
      <el-date-picker v-model="planMemotime" 
        type="date" placeholder="选择考试日期" 
        @change="dateChange" format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd" style="width:100%;margin-top: 20px;" :picker-options="pickerOptions" >
      </el-date-picker>
    </template>
  </div>
</template>

<script>
import { showMessage } from '../../libs/message'
import dayjs from 'dayjs';
export default {
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    },
    planMemoScene:{
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
        }
      },
      planMemotime:'',// 日期是用在考试模式的
      currentIndex: 0,
      planMemoTime: 30,
      planNewCardNum: 60,
      tabList: [
        {
          name: '按每天投入时间',
        },
        {
          name: '按每天学习新卡数',
        },
      ],
      timeList: [{
        value: '5',
        label: '5'
      }, {
        value: '10',
        label: '10'
      }, {
        value: '20',
        label: '20'
      },
      {
        value: '30',
        label: '30'
      },
      {
        value: '60',
        label: '60'
      },
      {
        value: '90',
        label: '90'
      },
      {
        value: '120',
        label: '120'
      },
      {
        value: '150',
        label: '150'
      },
      {
        value: '180',
        label: '180'
      },
      {
        value: '210',
        label: '210'
      }
      ],
      cardList: [
        {
          value: '10',
          label: '10'
        }, {
          value: '20',
          label: '20'
        },
        {
          value: '30',
          label: '30'
        },
        {
          value: '50',
          label: '50'
        },
        {
          value: '60',
          label: '60'
        },
        {
          value: '80',
          label: '80'
        },
        {
          value: '100',
          label: '100'
        },
        {
          value: '150',
          label: '150'
        },
        {
          value: '200',
          label: '200'
        },
        {
          value: '300',
          label: '300'
        }
      ]
    }
  },
  methods: {
    dateChange(v){
      this.$emit('setPlanMemoTime',v)
    },
    tabClick(item, index) {
      this.currentIndex = index
    },
    timeChange(time) {
      const time1 = this.checkNum(time)
      this.$emit('setPlanMemoTime',time1)
    },
    numChange(cardNum) {
      const num = this.checkNum(cardNum)
      this.$emit('setPlanNewCardNum',num)
      this.$emit('setPlanCardNum',num)
    },
    checkNum(num) {
      if (isNaN(Number(num))) {
        showMessage({ message: '请输入数字！', type: 'warning' })
      } else if (Number(num) < 0) {
        showMessage({ message: '请输入大于0的数!', type: 'warning' })
      } else {
        return Number(num)
      }
    },
  },
  computed:{
    surplusDay(){
      // 剩余天数
      let now = dayjs(dayjs((new Date).getTime()).format('YYYY-MM-DD'))
      if(isNaN(dayjs(this.planMemotime).diff(now,'hour'))){
        return ''
      }
      if(dayjs(this.planMemotime).diff(now,'hour') <= 0) {
        return '0天'
      }else{
        return dayjs(this.planMemotime).diff(now,'hour')/ 24 + '天'
      }
    }
  },
  watch:{
    currentIndex(n){
      if(n == 1){
        this.$emit('setPlanNewCardNum',this.planNewCardNum)
      }else if(n == 0){
        this.$emit('setPlanNewCardNum',0)
      }
    }
  },
  mounted() {
    this.$emit('setPlanNewCardNum',0)
    this.$emit('setPlanMemoTime',this.planMemoTime)
  },
}
</script>

<style lang="scss" scoped>

.plan-num {
  .plan-num-tab {
    display: flex;
    height: 44px;
    background-color: #fff;
    border-radius: 12px;

    .plan-tab-btn {
      border-radius: 12px;
      width: 50%;
      font-weight: 500;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #202020;

      &:hover {
        cursor: pointer;
      }

      &.active {
        background: #FFA800;
        color: #fff;
      }
    }
  }

  .plan-tips {
    text-align: center;
    font-size: 12px;
    color: rgba(0, 0, 10, 0.4);
    line-height: 17px;
    margin-top: 12px;
  }

  .studyTime {
    margin-top: 20px;
    width: 100%;

    .text {
      font-size: 14px;
      margin: 10px 0;
    }

    .el-select {
      width: 100%;
    }
  }

  
  .plan-deadline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    background-color: #fff;
    border-radius: 12px;
    padding: 0 8px 0 18px;

    .text {
      font-size: 16px;
      font-weight: 500;
      color: #202020;
    }

    .time {
      font-size: 16px;
      font-weight: 500;
      color: #FFA800;
    }
  }
  .plan-setplan {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .plan-newcard-num {
      width: 168px;

      p {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.6);
      }
    }

    .plan-day {
      width: 168px;

      p {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.6);
      }
    }
  }

  .back {
    margin-top: 20px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: rgba(32, 32, 32, 0.6);

    &:hover {
      cursor: pointer;
    }
  }
}

::v-deep .el-input__inner {
  border-radius: 8px;
}
</style>
