<template>
  <div class="status-box">
    <div class="status-item" v-for="(item, index) in labelTagList" :key="index" @click="labelClick(item, index)">
      <div class="left" :class="item.isShow ? 'active' : ''">
        <div class="status-item-name">{{item.name}}&nbsp;&nbsp;</div>
        <div class="status-item-num">{{item.num }}</div>
      </div>
      <div class="right">
        <svg-icon iconClass="tick" class="w-24 h-24" v-show="item.isShow"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../../libs/api.request'
export default {
  props: {
    propSelectTagList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTagsList: [], //选中的tags，
      selectedList: [], // 选中的菜单，方便下面展示
      labelTagList: []
    }
  },
  methods: {
    getLabelTags() {
      request.request({
        url: '/card/getTags',
        params: { chapterId: this.$route.params.chapterId || 0 }
      }).then(res => {
        res.list.forEach(v=> {
          v.isShow = false
        })
        this.labelTagList = res.list
      })
    },
    labelClick(item, index) {
      item.isShow = !item.isShow
      this.$forceUpdate()
      if (item.isShow) {
        this.selectedList.push(item)
        this.selectedTagsList.push(item.name)
      } else {
        for (let i = 0; i < this.selectedList.length; i++) {
          if (this.selectedList[i].id === item.id) {
            this.selectedList.splice(i, 1)
            this.selectedTagsList.splice(i, 1)
            break
          }
        }
      }
      this.$emit('handleTagsSelect', this.selectedList, this.selectedTagsList)
    }
  },
  watch: {
    propSelectTagList(newValue) {
      if (newValue.length === 0) {
        for (let i = 0; i < this.labelTagList.length; i++) {
          this.labelTagList[i].isShow = false
        }
      } else {
        for (let i = 0; i < this.labelTagList.length; i++) {
          for (let j = 0; j < newValue.length; j++) {
            if (newValue[j] === this.labelTagList[i].name) {
              this.labelTagList[i].isShow = true
              break
            } else {
              this.labelTagList[i].isShow = false
            }
          }
        }
      }
    },
    $route() {
      if(this.$route.name === 'Chapter' || this.$route.name === 'CardAll') {
        this.selectedList = []
        this.labelTagList = []
        this.getLabelTags()
      }
    }
  },
  created() {
    this.getLabelTags()
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/css/mixin.scss';

.status-box {
  position: absolute;
  top: 38px;
  right: 0;
  z-index: 999;
  background-color: #fff;
  width: 200px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow-y: scroll;
  max-height: 264px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }

  .status-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 12px 0 16px;

    .left {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #202020;

      &.active {
        font-weight: 500;
      }

      .status-item-name {
        // width: 80%;
        @include clamp(1);
      }
      .status-item-num {
        opacity: .4;
        width: max-content;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }
}
</style>