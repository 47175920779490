<template>
  <div class="status-box">
    <div class="status-item" v-for="(item, index) in statusList" :key="item.status" @click="statusClick(item, index)">
      <div class="left">
        <svg-icon :iconClass="item.icon"></svg-icon>
        <div class="status-item-name" :class="currentIndex === index ? 'active' : ''">{{ item.name }} <span style="opacity: .4;"> {{ item.num }}</span>
        </div>
      </div>
      <div class="right">
        <svg-icon iconClass="tick" v-show="item.isShow" class="w-24 h-24"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../../libs/api.request'
export default {
  props: {
    propSelectedStatusList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: 0,
      // 掌握程度
      statusList: [
        { status: 0, name: '全部卡片', icon: 'search_condition', isShow: true, num: '' },
        { status: 1, name: '新卡', icon: 'new_card', isShow: false, num: '' },
        { status: 2, name: '记忆中', icon: 'studying', isShow: false, num: '' },
        { status: 3, name: '已掌握', icon: 'handle', isShow: false, num: '' },
        { status: 4, name: '疑难', icon: 'hard_card', isShow: false, num: '' },
        { status: 5, name: '不复习', icon: 'no_review', isShow: false, num: '' }
      ],
      selectedList: [], //选中的status的每一项
      selectedStatusList: [] // 选中的status的值
    }
  },
  methods: {
    statusClick(item, index) {
      if (item.status === 0) {
        this.selectedList = []
        this.selectedStatusList = []
        item.isShow = true
        if (item.isShow) {
          // 选中全部后，别的取消勾选
          this.statusList.forEach(element => {
            if (element.status !== 0) {
              element.isShow = false
            }
          });
        }
      } else {
        this.statusList[0].isShow = false
        item.isShow = !item.isShow
        if (item.isShow) {
          this.selectedList.push(item)
          this.selectedStatusList.push(item.status)
        } else {
          for (let i = 0; i < this.selectedList.length; i++) {
            if (this.selectedList[i].status === item.status) {
              this.selectedList.splice(i, 1)
              this.selectedStatusList.splice(i, 1)
              break
            }
          }
        }
      }
      this.$emit('handleStatusClick', item, this.selectedList, this.selectedStatusList)
    },
    getCategoryData() {
      request.request({
        url: "/card/countNum",
        params: {
          chapterId: this.$route.params.chapterId || 0
        }
      }).then(res => {
        this.statusList[0].num = res.totalCardNum
        this.statusList[1].num = res.unStudyCardNum // 新卡
        this.statusList[2].num = res.lrnCardNum //学习中
        this.statusList[3].num = res.handledCardNum // 已经掌握
        this.statusList[4].num = res.hardCardNum // 疑难
        this.statusList[5].num = res.suspendCardNum // 不复习
      })
    }
  },
  watch: {
    $route() {
      if(this.$route.name === 'Chapter'){
        this.getCategoryData()
      }
      this.selectedList = []
      this.selectedStatusList = []
    },
    propSelectedStatusList(newValue) {
      if (newValue.length === 0) {
        for (let i = 0; i < this.statusList.length; i++) {
          this.statusList[i].isShow = false
        }
      } else {
        for (let i = 0; i < this.statusList.length; i++) {
          for (let j = 0; j < newValue.length; j++) {
            if (newValue[j] === this.statusList[i].status) {
              this.statusList[i].isShow = true
              break
            } else {
              this.statusList[i].isShow = false
            }
          }
        }
      }
    },
  },
  mounted() {
    this.getCategoryData()
  },
}
</script>

<style lang="scss" scoped>
.status-box {
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 999;
  background-color: #fff;
  width: 188px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;

  .status-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 0 12px 0 16px;

    .left {
      display: flex;
      align-items: center;

      .status-item-name {
        font-size: 14px;
        color: #202020;
        margin-left: 16px;

        &.active {
          font-weight: 500;
        }
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }
}
</style>