<template>
  <div class="toggle-menu-box">
    <div class="wx-box">
      <svg-icon iconClass="wechat" @mouseenter="showWxbox = true" @mouseleave="showWxbox = false"></svg-icon>
      <transition name="el-fade-in-linear">
        <div class="wx-content" v-show="showWxbox">
          <div class="title">产品反馈</div>
          <img src="../../../public/static/image/wxqun.jpg" class="w-fill h-fill wx-img" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CommonMenu from './CommonMenu.vue'
export default {
  components: {
    CommonMenu
  },
  data() {
    return {
      value: '',
      showWxbox: false,
      menuList: [
        { icon: 'toggle_mulu', id: 1 },
        { icon: 'toggle_waterfall', id: 2 },
        { icon: 'toggle_list', id: 3 },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.toggle-menu-box {
  position: relative;

  .wx-box {
    position: relative;

    &:hover {
      cursor: pointer;
    }

    .wx-content {
      padding: 12px;
      position: absolute;
      top: 30px;
      background-color: #fff;
      padding-bottom: 0;

      .wx-img {
        display: block;
        width: 300px;
      }

      .title {
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }

  }
}
</style>