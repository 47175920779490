<template>
  <div class="status-box">
    <div class="status-item" v-for="(item, index) in flagList" :key="item.flag" @click="flagClick(item, index)">
      <div class="left">
        <svg-icon :iconClass="item.icon"></svg-icon>
        <div class="status-item-name" :class="item.isShow ? 'active' : ''">{{ item.name }}</div>
      </div>
      <div class="right">
        <svg-icon iconClass="tick" class="w-24 h-24" v-show="item.isShow"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propSelectFlagList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: 0,
      // 掌握程度
      flagList: [
        { flag: 0, name: '无标记', icon: 'search_flag', isShow: false },
        { flag: 1, name: '红色', icon: 'red_flag', isShow: false },
        { flag: 2, name: '黄色', icon: 'yellow_flag', isShow: false },
        { flag: 3, name: '蓝色', icon: 'blue_flag', isShow: false }
      ],
      selectedFlagList: [], //选中的flae，
      selectedList: [] // 选中的菜单，方便下面展示
    }
  },
  methods: {
    flagClick(item, index) {
      item.isShow = !item.isShow
      if (item.isShow) {
        this.selectedList.push(item)
        this.selectedFlagList.push(item.flag)
      } else {
        for (let i = 0; i < this.selectedList.length; i++) {
          if (this.selectedList[i].flag === item.flag) {
            this.selectedList.splice(i, 1)
            this.selectedFlagList.splice(i, 1)
            break
          }
        }
      }
      this.$emit('handleFlagSelect', item, this.selectedList, this.selectedFlagList)
    }
  },
  watch: {
    propSelectFlagList(newValue) {
      if (newValue.length === 0) {
        for (let i = 0; i < this.flagList.length; i++) {
          this.flagList[i].isShow = false
        }
      } else {
        for (let i = 0; i < this.flagList.length; i++) {
          for (let j = 0; j < newValue.length; j++) {
            if (newValue[j] === this.flagList[i].flag) {
              this.flagList[i].isShow = true
              break
            } else {
              this.flagList[i].isShow = false
            }
          }
        }
      }
    },
    $route(){
      this.selectedFlagList = []
      this.selectedList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.status-box {
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 999;
  background-color: #fff;
  width: 180px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;

  .status-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 12px 0 16px;

    .left {
      display: flex;
      align-items: center;

      .status-item-name {
        font-size: 14px;
        color: #202020;
        margin-left: 16px;

        &.active {
          font-weight: 500;
        }
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }
}
</style>