<template>
  <div class="chapter-menu">
    <!-- v-if="isShowBeginBTn && $route.name === 'CardAll'" -->
    <el-button v-if="$route.name === 'CardAll'"
               class="w-94 h-34 bdr-8 display-flex align-items-center justify-content-center" type="primary"
               @click="beginCardAllStudyClick"
               :disabled="$store.state.totalChapterData.todayNewCardNum + $store.state.totalChapterData.todayRevCardNum === 0"
               :style="{ background: btnBgc, borderColor: btnBgc }">
      待记忆{{ studyNum }}
    </el-button>
    <el-button v-if="isShowBeginBTn && $route.name === 'Chapter'"
               class="w-94 h-34 bdr-8 display-flex align-items-center justify-content-center" type="primary"
               @click="beginStudyClick"
               :disabled="chapter.status !== 1 || chapter.newCardNum[1] + chapter.revCardNum[1] + chapter.revCardNum[2] === 0"
               :style="{ background: chapter.status !== 1 || chapter.newCardNum[1] + chapter.revCardNum[1] + chapter.revCardNum[2] === 0 ? '#ccc' : '', borderColor: chapter.status !== 1 || chapter.newCardNum[1] + chapter.revCardNum[1] === 0 ? '#ccc' : '' }">
      {{ showBtnText() }}
    </el-button>
    <el-input v-model="keyword" v-show="!isShowSearch" placeholder="输入关键词或标签" ref="myInput" @blur="onInputBlur"
              @keydown.enter.native="onHandleSearch($event)" clearable @clear="onClear" class="my-input mg-l-20">
      <div slot="prefix" class="display-flex align-items-center w-fill h-32  justify-content-center">
        <svg-icon iconClass="input_search"></svg-icon>
      </div>
    </el-input>
    <div class="menu-box">
      <svg-icon iconClass="search" class="w-16 icon" @click="openSearch" v-show="isShowSearch" title="搜索"
                position="bottom"></svg-icon>
      <svg-icon :iconClass="$store.state.isSelectAll ? 'select_all_card_active' : 'select_all_card'"
                class="w-16 icon" @click="openSelectAll" title="批量操作" position="bottom"></svg-icon>
      <div class="position-relative">
        <!-- <svg-icon iconClass="share" class="w-16 icon c-app-red" v-show="$route.name === 'Chapter'" @click="openShare"
                  title="分享" position="bottom">
        </svg-icon> -->
        <div v-show="chapter && chapter.pubInfo && chapter.pubInfo.dataVer > chapter.pubInfo.ver"
             class="position-absolute w-8 h-8 bg-app-red bdr-4" style="right:-8px;top: -2px"></div>
      </div>
      <el-dropdown :show-timeout="0"
                   v-if="chapter !== undefined && chapter !== null && $route.name === 'Chapter' && Object.keys(chapter).length > 0">
        <svg-icon iconClass="chapter_more_menu" class="w-16 h-16 icon" v-show="$route.name === 'Chapter'"></svg-icon>
        <el-dropdown-menu slot="dropdown" style="border-radius: 8px;width: 180px;">
          <el-dropdown-item @click.native="handleDropDownClick(menu)" v-for="menu in menuList" :key="menu.id">
            <svg-icon :iconClass="menu.icon" class="mg-r-14"></svg-icon>
            <template v-if="menu.id !== 4">
              <div class="" v-if="menu.id === 3 && chapter.status">{{
                  chapter.status === 1 ? '停止学习' : '恢复学习'
                }}
              </div>
              <div v-else>{{ menu.menuName }}</div>
            </template>
            <div v-else>记忆强度{{ memoryStrength(chapter) }}</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import request from '../../libs/api.request'
import MakeCard from '../MakeCard.vue'
import {showMessage} from '../../libs/message'
import {handleChapterOperation} from '../../libs/message'
import SetStudyPlan from '../study/SetStudyPlan.vue'

export default {
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    },
    isShowBeginBTn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MakeCard,
    SetStudyPlan
  },
  data() {
    return {
      keyword: '',
      isShowSearch: true,
      isShowSetPlan: false,
      menuList: [
        {menuName: '修改卡牌盒', icon: 'edit', id: 1},
        {menuName: '重设学习进度', icon: 'reset_study_progress', id: 2},
        {menuName: '停止学习', icon: 'stop_study', id: 3},
        {menuName: '导出卡牌盒', icon: 'export_chapter', id: 5},
        {menuName: '拷贝卡牌盒', icon: 'copy_chapter', id: 6},
        {menuName: '删除卡牌盒', icon: 'delete_box', id: 7},
        {menuName: '调整学习偏好', icon: 'studyPlan', id: 8},
        // { menuName: '朗读设置', icon: 'read_setting', id: 5 },
        // { menuName: '分享/售卖卡牌盒', icon: 'dropdown_share_menu', id: 6 },
      ],
      isShowSelectAll: false
    }
  },
  methods: {
    openSelectAll() {
      this.$store.commit('setSelectAll', !this.$store.state.isSelectAll)
      this.isShowSelectAll = !this.isShowSelectAll
    },
    onClear() {
      console.log(this.keyword);
      this.onHandleSearch()
    },
    onHandleSearch() {
      this.keyword.trim()
      this.$emit('handleSearch', this.keyword)
    },
    openShare() {
      if (this.chapter.parentId) {
        showMessage({message: '子卡牌盒不允许单独发布', type: 'error'})
        return;
      }
      this.$emit('handleOpenShare')
    },
    // 全部卡牌下面的开始学习
    beginCardAllStudyClick() {
      this.$emit('handleBeginStudy')
    },
    // 卡牌盒子详情里面的学习
    beginStudyClick() {
      this.$emit('beginStudyClick')
    },
    // 全部卡牌里面的学习
    handleBeginStudy() {
      this.$emit('handleBeginStudy')
    },
    openSearch() {
      this.isShowSearch = !this.isShowSearch
      this.$nextTick(() => {
        this.$refs.myInput.focus()
      })
    },
    onInputBlur() {
      if (this.keyword.trim().length === 0) {
        this.isShowSearch = !this.isShowSearch
      }
    },
    renameChapter() {
      this.$prompt('', '重命名卡牌盒', {
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        inputValue: this.chapter.name,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue && instance.inputValue !== this.chapter.name) {
            request.request({
              url: '/chapter/save',
              method: 'post',
              data: {
                chapterId: this.chapter.chapterId,
                name: instance.inputValue
              }
            }).then((res) => {
              this.chapter.name = instance.inputValue;
              showMessage({message: '修改成功！', type: 'success'})
              this.$store.dispatch('modifyChapterList')
              done();
            }).catch((e) => {
              showMessage({message: e.message, type: 'error'})
            })
          } else {
            done();
          }
        }
      });
    },
    hanldeStudySetting() {
      let status = null
      let title = ''
      if (this.chapter.status === 1) {
        status = 2
        title = '是否要暂停学习该卡牌盒？'
      } else {
        status = 1
        title = '是否要恢复学习该卡牌盒？'
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        request.request({
          url: '/chapter/changeStatus',
          data: {
            chapterId: this.chapter.chapterId,
            status // status : 1是恢复学习，2是停止学习
          },
          method: 'post'
        }).then(res => {
          showMessage({message: '设置成功!', type: 'success'})
          this.$store.dispatch('modifyChapterList')
          this.$store.dispatch('modifyAllChapterList')
          this.$parent.$parent.getChapterInfo() // 更新头部数据
        }).catch(e => {
          showMessage({message: e.message, type: 'error'})
        })
      }).catch(e => {
      })
    },
    exportChapter() {
      let loading = this.$loading({
        lock: true,
        text: '正在导出',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });
      request.request({
        url: '/chapter/exportExcel',
        params: {chapterId: this.chapter.chapterId}
      }).then(ret => {
        loading.close();
        location.assign("https://amazingmemo.com/api/index/chapter/getExportFile?file=" + ret.file);
      }).catch(e => {
        loading.close();
        showMessage({message: e.message, type: 'error'})
      });
    },
    copyChapter() {
      if (this.chapter.creatorId !== this.chapter.userId) {
        return showMessage({message: "非自建卡牌盒不允许拷贝", type: 'error'})
      }
      handleChapterOperation(this, `拷贝 ${this.chapter.name} 吗?`, '拷贝卡牌盒',
        '/chapter/copy', {chapterId: this.chapter.chapterId}, '拷贝成功',
        () => {
          setTimeout(() => {
            location.reload()
          }, 500)
        })
    },
    //重设学习进度
    resetStudyProgress() {
      this.$confirm('是否重置该卡牌盒内(包括子卡牌盒)的卡牌学习进度?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request.request({
              url: '/chapter/resetProgress',
              method: 'post',
              data: {chapterId: this.chapter.chapterId,}
            }).then((data) => {
              showMessage({message: '重置学习进度成功!', type: 'success'})
              this.$parent.$parent.getChapterInfo()
              done();
              // this.$emit('refreshData')
            }).catch((e) => {
              showMessage({message: e.message, type: 'error'})
              showScrollBar()
            })
          } else {
            done();
          }
        }
      });
    },

    handleDropDownClick(menu) {
      if (menu.id === 1) {
        if (this.chapter.parentId !== 0) {
          this.renameChapter()
        } else {
          this.$emit('modifyChapter', this.chapter)
        }
      }
      switch (menu.id) {
        case 2:
          this.resetStudyProgress()
          break;
        case 3:
          this.hanldeStudySetting()
          break;
        case 5:
          this.exportChapter()
          break;
        case 6:
          this.copyChapter()
          break;
        case 7:
          handleChapterOperation(this, `确定删除 ${this.chapter.name} 吗?此操作将会删除该卡牌盒下的所有卡牌`, '删除卡牌盒',
            '/chapter/delete', {chapterId: this.chapter.chapterId}, '删除成功',
            () => {
              this.$store.dispatch('modifyChapterList')
              this.$store.dispatch('modifyAllChapterList')
              if (this.chapter.parentId === 0) {
                this.$router.push('/home/studyPlatform/cardAll')
              } else {
                this.$router.push('/home/studyPlatform/chapter/' + this.chapter.parentId)
              }
            })
          break;
        case 8:
          this.$emit('openAdjustPlan')
          break;
      }
    }
  },
  computed: {
    studyNum() {
      return this.$store.state.totalChapterData.todayRevCardNum + this.$store.state.totalChapterData.todayNewCardNum
    },
    btnBgc() {
      return this.$store.state.totalChapterData.todayRevCardNum + this.$store.state.totalChapterData.todayNewCardNum === 0 ? '#ccc' : ''
    },
    memoryStrength() {
      return (chapter) => {
        switch (chapter.planLrnLevel) {
          case 1:
            return '(低)'
          case 2:
            return '(中)'
          case 3:
            return '(高)'
          case 4:
            return '(强)'
        }
      }
    },
    showBtnText() {
      return () => {
        if (this.chapter.newCardNum[0] + this.chapter.revCardNum[0] > 0) {
          return '待记忆' + (this.chapter.newCardNum[0] + this.chapter.revCardNum[0])
        } else {
          return '增加学习量'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  height: 48px !important;
  width: 180px;
  padding-left: 16px;
  color: #202020;

  &:hover {
    background-color: #f7f7f7;
    color: #202020;
  }
}

.chapter-menu {
  display: flex;
  align-items: center;

  ::v-deep .el-input__inner {
    animation: keyf .2s;
  }

  @keyframes keyf {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 180px;
      opacity: 1;
    }
  }

  .menu-box {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .study-btn {
    width: 104px;
    height: 38px;
    font-size: 14px;
    border-radius: 10px;
    margin-left: 24px;
  }

  ::v-deep .el-input {
    width: max-content;
  }

  ::v-deep .el-input__inner {
    width: 180px;
    height: 32px;
    line-height: 32px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 2px solid #FFA800;
    transition: all .3s;
  }

  ::v-deep .el-input__prefix {
    left: 12px !important;
    display: flex;
    align-items: center;
  }

  ::v-deep .el-input__suffix {
    // top: -4px;
    display: flex;
    align-items: center;
  }
}
</style>
