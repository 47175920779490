<template>
  <div class="chapter-menu">
    <template v-for="(item, index) in menuList " >
      <div class="menu-item" @click="menuClick(item, index)"
        :class="currentIndex === index ? 'active' : ''" :style="{ width: width + 'px' }">
        <svg-icon :iconClass="item.icon" :title="item.title" position="bottom"></svg-icon>
        <svg-icon iconClass="search_arrow_down" class="w-8 h-8 mg-l-4" v-if="showArrow"></svg-icon>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    width: {
      type: Number
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: ''
    }
  },
  methods: {
    menuClick(item, index) {
      this.currentIndex = index
      this.$emit('menuClick', item)
    }
  },
}
</script>
<style lang="scss" scoped>
.chapter-menu {
  display: flex;

  .menu-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    height: 24px;
    border-radius: 6px;

    &.active {
      background-color: #EDEDED;
    }

    &:hover {
      cursor: pointer;
      background-color: #EDEDED;
    }
  }
}
</style>