<template>
  <div class="common-head—container">
    <div class="common-head-nav-box">
      <ToggleMenu class="head-menu "></ToggleMenu>
      <SearchMenu @handleFlagSelect="handleFlagSelect" @handleStatusSelect="handleStatusSelect"
        @handleTagsSelect="handleTagsSelect" @handleSortSelect="handleSortSelect"
        :selectedStatusList="selectedStatusList" :selectFlagList="selectFlagList" :selectTagList="selectTagList">
      </SearchMenu>
      <ChapterOperationMenu class="head-menu head-menu-right" 
        @handleBeginStudy="handleBeginStudy" 
        :chapter="chapter"
        :isShowBeginBTn="isShowBeginBTn" @openAdjustPlan="openAdjustPlan"
        @beginStudyClick="beginStudyClick" @handleOpenShare="handleOpenShare" @handleSearch="handleSearch" @modifyChapter="modifyChapter">
      </ChapterOperationMenu>
    </div>
    <div class="search-content-box">
      <div class="search-item" v-for="(value, index) in statusList">
        <div class="search-name">{{ value.name }} {{ value.num }}</div>
        <i class="el-icon-close fs-12 cursor-pointer" style="color:#202020;opcity:.4"
          @click="deleteStatus(value, index)"></i>
      </div>
      <div class="search-item" v-for="(item, index1) in flagList">
        <div class="search-name">{{ item.flag !== 0 ? '标记' : '' }}{{ item.name }}</div>
        <i class="el-icon-close fs-12 cursor-pointer" style="color:#202020;opcity:.4"
          @click="deleteFlag(item, index1)"></i>
      </div>
      <div class="search-item" v-for="(item, index2) in tagList">
        <div class="search-name">{{ item.name }}</div>
        <i class="el-icon-close fs-12 cursor-pointer" style="color:#202020;opcity:.4"
          @click="deleteTag(item, index2)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ChapterOperationMenu from '../headerMenuNav/ChapterOperationMenu.vue'
import SearchMenu from '../headerMenuNav/SearchMenu.vue'
import ToggleMenu from '../headerMenuNav/ToggleMenu.vue'
export default {
  props: {
    chapter: {
      type: Object,
      default: () => { }
    },
    isShowBeginBTn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ChapterOperationMenu,
    SearchMenu,
    ToggleMenu,
  },
  data() {
    return {
      flagList: [],
      statusList: [],
      tagList: [],
      //---------- 下面的是用来筛选的，上面是渲染用的
      selectedStatusList: [],
      selectFlagList: [],
      selectTagList: []
    }
  },
  methods: {
    modifyChapter(chapter){
      this.$emit('modifyChapter',chapter)
    },
    handleSortSelect(sortId) {
      this.$emit('handleSortSelect', sortId)
    },
    handleTagsSelect(tagMenuList, selectTagList) {
      this.tagList = tagMenuList
      this.selectTagList = selectTagList
      this.$emit('handleTagSelect', selectTagList)
    },
    handleSearch(keyword) {
      this.$emit('handleSearch', keyword)
    },
    deleteStatus(item, index) {
      this.statusList.splice(index, 1)
      this.selectedStatusList.splice(index, 1)
      this.$emit('handleStatusSelect', this.selectedStatusList)
    },
    deleteFlag(item, index) {
      this.flagList.splice(index, 1)
      this.selectFlagList.splice(index, 1)
      this.$emit('handleFlagSelect', this.selectFlagList)
    },
    deleteTag(item, index) {
      this.tagList.splice(index, 1)
      this.selectTagList.splice(index, 1)
      this.$emit('handleTagSelect', this.selectTagList)
    },
    handleStatusSelect(statusMenuList, selectedStatusList) {
      this.statusList = statusMenuList
      this.selectedStatusList = selectedStatusList
      this.$emit('handleStatusSelect', this.selectedStatusList) // 选中的status:[1,23]
    },
    handleFlagSelect(flagList, selectFlagList) {
      this.flagList = flagList
      this.selectFlagList = selectFlagList
      this.$emit('handleFlagSelect', this.selectFlagList) // 选中的status:[1,23]
    },
    handleOpenShare() {
      this.$emit('handleOpenShare')
    },
    // 卡牌盒详情的学习
    beginStudyClick() {
      this.$emit('beginStudyClick')
    },
    openAdjustPlan() {
      this.$emit('openAdjustPlan')
    },
    setPlanSuccess() {
      this.isShowSetPlan = false
    },
    handleCancelClick() {
      this.isShowSetPlan = false
    },
    // 全部卡牌里面的学习
    handleBeginStudy() {
      this.$emit('handleBeginStudy')
    },
  },
  watch: {
    $route() {
      this.flagList = []
      this.statusList = []
      this.tagList = []
      this.selectedStatusList = []
      this.selectTagList = []
      this.selectFlagList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.common-head—container {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #f7f7f7;
  padding: 0 24px;

  .common-head-nav-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;

    .head-menu {
      flex: 1;

      &.head-menu-right {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .search-content-box {
    display: flex;
    flex-wrap: wrap;

    // margin-bottom: 6px;
    .search-item {
      padding: 11px;
      padding-left: 13px;
      background-color: #fff;
      height: 36px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      margin-right: 10px;
      margin-bottom: 10px;

      .search-name {
        font-size: 14px;
        color: #202020;
        line-height: 14px;
        margin-right: 9px;
      }
    }
  }
}
</style>
