<template>
  <div class="position-relative">
    <CommonMenu :menuList="menuList" :showArrow="true" :width="42" @menuClick="handleMenuClick"></CommonMenu>
    <StatusMenu 
      v-show="showStatusMenu" 
      @handleStatusClick="handleStatusClick"
      :propSelectedStatusList="selectedStatusList" 
      @mouseleave.native="showStatusMenu = false">
      </StatusMenu>
    <FlagMenu 
      @mouseleave.native="showFlagMenu = false"
      v-show="showFlagMenu" 
      @handleFlagSelect="handleFlagSelect" 
      :propSelectFlagList="selectFlagList">
    </FlagMenu>
    <SortMenu 
      @hanldeSelectSort="hanldeSelectSort" 
      v-show="showSortMenu" 
      @mouseleave.native="showSortMenu = false">
    </SortMenu>
    <LabelMenu 
      :propSelectTagList="selectTagList"
      v-show="showLabelMenu" 
      @handleTagsSelect="handleTagsSelect"
      @mouseleave.native="showLabelMenu = false">
    </LabelMenu>
  </div>
</template>
<script>
import CommonMenu from './CommonMenu.vue'
import StatusMenu from './searchMenuItem/StatusMenu.vue'
import FlagMenu from './searchMenuItem/FlagMenu.vue'
import SortMenu from './searchMenuItem/SortMenu.vue'
import LabelMenu from './searchMenuItem/LabelMenu.vue'
export default {
  props: {
    selectFlagList: {
      type: Array,
      default: () => []
    },
    selectedStatusList: {
      type: Array,
      default: () => []
    },
    selectTagList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CommonMenu,
    StatusMenu,
    FlagMenu,
    SortMenu,
    LabelMenu
  },
  data() {
    return {
      showStatusMenu: false,
      showFlagMenu: false,
      showSortMenu:false,
      showLabelMenu:false,
      menuList: [
        { icon: 'search_condition', id: 1,title:'掌握情况' },
        { icon: 'search_flag', id: 2,title:'标记' },
        { icon: 'search_label', id: 3,title:'标签' },
        { icon: 'search_toggle', id: 4,title:'排序' }
      ],
    }
  },
  methods: {
    hanldeSelectSort(selectSortId){
      this.showSortMenu = false
      this.$emit('handleSortSelect',selectSortId)
    },
    handleTagsSelect(tagMenuList,selectTagList){
      this.showLabelMenu = false
      this.$emit('handleTagsSelect', tagMenuList, selectTagList)
    },
    handleStatusClick(menu, selectedList, selectedStatusList) {
      if (menu.status === 0 || selectedList.length === 0) {
        this.menuList[0].icon = 'search_condition'
      } else {
        this.menuList[0].icon = menu.icon
      }
      this.showStatusMenu = false
      this.$emit('handleStatusSelect', selectedList, selectedStatusList)
    },
    handleFlagSelect(menu, selectMenuList, flagList) {
      if (menu.flag === 0 || selectMenuList.length === 0) {
        this.menuList[1].icon = 'search_flag'
      } else {
        this.menuList[1].icon = menu.icon
      }
      this.showFlagMenu = false
      this.$emit('handleFlagSelect', selectMenuList, flagList)
    },
    handleMenuClick(menu) {
      switch (menu.id) {
        case 1:
          this.showStatusMenu = !this.showStatusMenu
          this.showFlagMenu = false
          this.showSortMenu = false
          this.showLabelMenu = false
          break;
        case 2:
          this.showStatusMenu = false
          this.showSortMenu  = false
          this.showLabelMenu = false
          this.showFlagMenu = !this.showFlagMenu
          break;
        case 3:
          this.showLabelMenu = !this.showLabelMenu
          this.showStatusMenu = false
          this.showSortMenu  = false
           this.showFlagMenu = false
          break;
        case 4:
          this.showSortMenu = !this.showSortMenu
          this.showStatusMenu = false
          this.showLabelMenu = false
          this.showFlagMenu = false
          break;
      }
    }
  },
  watch: {
    selectFlagList(n) {
      if (n.length === 0) {
        this.menuList[1].icon = 'search_flag'
      }
    },
    selectedStatusList(n) {
      if (n.length === 0) {
        this.menuList[0].icon = 'search_condition'
      }
    },
    $route() {
      
    }
  }
}
</script>
<style lang="scss" scoped>
</style>