<template>
  <div class="status-box">
    <div class="status-item" v-for="(item, index) in sortList" :key="index" @click="sortClick(item, index)">
      <div class="left">
        <div class="status-item-name" :class="currentIndex === index ? 'active' : ''">{{ item.name }}</div>
      </div>
      <div class="right">
        <svg-icon iconClass="tick" class="w-24 h-24" v-show="currentIndex === index"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propSelectFlagList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: 0,
      // 掌握程度
      sortList: [
        { id: '', name: '默认排序', isShow: false },
        { id: 'fld-asc', name: '按名称正序', isShow: false },
        { id: 'fld-desc', name: '按名称倒序', isShow: false },
        { id: 'ncrt-asc', name: '创建时间正序', isShow: false },
        { id: 'ncrt-desc', name: '创建时间倒序', isShow: false },
      ],
      selectedSortId: ''
    }
  },
  methods: {
    sortClick(item, index) {
      if (this.currentIndex === index) return
      this.currentIndex = index
      this.selectedSortId = item.id
      localStorage.setItem('sort', item.id)
      this.$emit('hanldeSelectSort', this.selectedSortId)
    }
  },
  created() {
    if (localStorage.getItem('sort')) {
      this.selectedSortId = localStorage.getItem('sort')
      for(let i = 0 ; i < this.sortList.length; i++) {
        if(this.sortList[i].id === this.selectedSortId){
          this.currentIndex = i
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.status-box {
  position: absolute;
  top: 38px;
  right: -50px;
  z-index: 999;
  background-color: #fff;
  width: 180px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;

  .status-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 12px 0 16px;

    .left {
      display: flex;
      align-items: center;

      .status-item-name {
        font-size: 14px;
        color: #202020;

        &.active {
          font-weight: 500;
        }
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }
}
</style>